import React, { useEffect, useState, useRef } from 'react';
import Autocomplete, {} from '@material-ui/lab/Autocomplete';
import {
  Typography,
  TextField,
  Grid,
  FormGroup,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Button,
  Box,
  FormControl,
  Switch,
  Select,
  MenuItem,
  ListItemText,
  Input
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import DialogActions from '@material-ui/core/DialogActions';
import Rating from '@material-ui/lab/Rating';
import theme from '../../../theme';
import { getMetiersSearch } from '../../../Functions/Get-query/getMetiersSearch';
import { getMetiersSearchByWorkType } from '../../../Functions/Get-query/getMetiersSearchByWorkType';
import { getJobRequirements } from '../../../Functions/Get-query/getJobRequirements';
import useSessionContext from '../../../sessions/useSessionContext';
import WorkTypeAutoComplete from '../../WorkTypeAutoComplete';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  addButton: {
    minWidth: '50px',
    height: '50px',
    margin: theme.spacing(3, 0, 3),
    padding: 0,
  },
  reverseButtonStyle: {
    color: '#e5087e',
    backgroundColor: '#ffffff',
    borderRadius: '55px',
    border: 'none',
    boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.3)',

    '&:hover': {
      color: '#fff',
      backgroundColor: '#e5087e',
    },
    '&:focus, &.focus': {
      color: '#fff',
      backgroundColor: '#e5087e',
      boxShadow: '0 0 0 .2rem rgba(#FF1C96, 0.2)',
      borderWidth: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '20px',
    },
  },
  buttonStyle: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
  smallTitle: {
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    textShadow: 'none',
    color: 'black',
    fontSize: '1.3rem',
    fontWeight: '300',
    margin: '2vh 0',
  },
  orLine: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'abosolute',
      top: 0,
      left: 0,
      width: '100px',
      height: '1px',
      backgroundColor: '#000',
    },
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
  html: {
    height: '100%',
    margin: 0,
  },
  root: {
    display: 'block',
    margin: '2rem 0',
    width: '100%',
    '& span': {
      fontSize: '1.3rem',
      fontWeight: ' 300',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: `${CheckIcon}`,
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#000',
    backgroundImage: `${CheckIcon}`,
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: `${CheckIcon}`,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  radioLegend: {
    textAlign: 'left',
    fontSize: '1.15rem',
    fontWeight: '300',
    marginBottom: '2vh',
  },
  selectionLabel: {
    display: 'block',
    fontSize: '1.3rem',
    fontWeight: '600',
    marginBottom: '1rem',
  },
  fieldSetWrapper: {
    marginBottom: 24,
    borderRadius: 6,
  },
}));

const starsLabel = ['0 à 2 ans', '2 à 5 ans', '5 à 7 ans', '7 à 10 ans', '+ de 10 ans'];

// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles(theme);
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
      icon={<span className={classes.icon}/>}
      {...props}
    />
  );
}

let updatedJob;

const PretSelectionSteps = ({
  userType,
  jobLimit,
  onFormChange,
  handleClose,
  metierCreated,
  allUserMetier,
  formData
}) => {
  const classes = useStyles(theme);
  const [isOpen, setIsOpen] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [remote, setRemote] = useState(0);
  const [daysAvailability, setDaysAvailability] = useState(5);
  const [monthsAvailability, setMonthsAvailability] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [showNextSteps, setShowNextSteps] = useState(false);
  const [initialData, setInitialdata] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [numberOfStars, setNumberOfStars] = useState({});
  const jobRef = useRef(null)

  const months = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre']

  const onNumberOfStarsChange = ({target}, newValue) => {
    const { name } = target;
    setNumberOfStars((numberOfStars => ({
      ...numberOfStars,
      [name]: newValue
    })));
  }

  useEffect(() => {
    if (Object.keys(formErrors).length > 0 && formErrors.verifyAll === true) {
      const items = document.getElementsByClassName('error');
      const visible = [...items].filter((el) =>
        // jQuery-like :visible selector
        !!(
          el.offsetWidth
          || el.offsetHeight
          || el.getClientRects().length
        ));

      if (visible.length > 0) {
        window.scrollTo({
          top: items[0].offsetTop,
          behavior: 'smooth',
        });
        setTimeout(() => {
          window.scrollBy(0, 0);
          setFormErrors({
            ...formErrors,
            verifyAll: false
          });
        }, 100);
      }
    }
  }, [formErrors]);

  const handleArrayChoice = (event) => {
    const data = event.target.value;
    if (data === undefined || selectedData === undefined) {
      return null;
    }
    const key = data.split(',')[0];
    const id = data.split(',')[1];
    let oldSelection = selectedData[key];

    if (oldSelection === undefined) {
      oldSelection = [];
    }

    let newSelection = [...oldSelection];

    if (key === 'experienceLevels') {
      if (!oldSelection.includes(id) && oldSelection.length === 0) {
        newSelection.push(id);
      } else {
        newSelection = [];
        newSelection.push(id);
      }
    } else if (!oldSelection.includes(id)) {
      newSelection.push(id);
    } else {
      newSelection = oldSelection.filter((e) => id !== e);
    }

    setSelectedData({
      ...selectedData,
      [key]: newSelection
    });
  };

  const handleChange = (event,type='remote') => {
    if(type=='remote'){
      setRemote(event.target.value)
    }else if(type=='days'){
      setDaysAvailability(event.target.value)
    }
    else if(type=='months'){
      setMonthsAvailability(event.target.value)
    }
    
  };

  function handleCheck(i, element) {
    let isChecked = false;
    if (Object.keys(initialData)[i] in selectedData) {
      if (selectedData[Object.keys(initialData)[i]].includes(element.id.toString())) {
        isChecked = true;
      }
    }
    return isChecked;
  }

  const renderCheckboxes = () => {
    const titleTrad = {
      generalEducation: 'Enseignement géréral',
      experienceLevels: 'Expérience de travail',
      specificEducation: 'Étude spécifique',
      workType: 'Type de travail',
      materialType: 'Outils de travail utilisés',
      otherSpecific: 'Autres spécifications',
    };

    return (
      Object.keys(initialData)
        .map((key, index) => {
          if (initialData[Object.keys(initialData)[index]].length === 0) {
            return false;
          }
          return (
            <fieldset className={classes.fieldSetWrapper}>
              <legend>
                {titleTrad[Object.keys(initialData)[index]]}
              </legend>
              <FormGroup>
                {initialData[Object.keys(initialData)[index]].map((element) => (
                  <>
                    <FormControlLabel
                      value={[Object.keys(initialData)[index], element.id, element.label]}
                      label={element.label}
                      control={<StyledRadio/>}
                      checked={handleCheck(index, element)}
                    />
                    { handleCheck(index, element) && Object.keys(initialData)[index] != 'experienceLevels'
                        && Object.keys(initialData)[index] != 'generalEducation' && Object.keys(initialData)[index] != 'specificEducation' &&
                      <>
                        <Typography component="legend">Niveau d'expérience</Typography>
                        <Grid container>
                          <Grid item xs={6}>
                            <Box component="fieldset" mb={3} borderColor="transparent">
                              <Rating
                                name={element.label}
                                value={numberOfStars[element.label] ?? 0}
                                onChange={onNumberOfStarsChange}
                              />
                            </Box>
                          </Grid>
                            <Grid item xs={6}>
                                <Typography component="legend">{starsLabel[numberOfStars[element.label] - 1]}</Typography>
                            </Grid>
                        </Grid>
                      </>
                    }
                  </>
                ))}
              </FormGroup>
            </fieldset>
          );
        })
    );
  };

  function createCheckBoxes() {
    return (
      <div className={classes.root}>
        <fieldset className={classes.fieldSetWrapper}>
        <legend className={classes.smallTitle}>
            Localisation                           
            </legend>
                <FormGroup>
                  <Select
                        value={remote}
                        onChange={(event) => handleChange(event)}
                        displayEmpty
                        className={classes.selectEmpty}
                      >
                        <MenuItem value={0}>En présentiel</MenuItem>
                        <MenuItem value={1}>100% Télétravail </MenuItem>
                        <MenuItem value={2}>Télétravail partiel</MenuItem>
                      </Select>
                </FormGroup>
        </fieldset>
        <FormLabel className={classes.selectionLabel}>
          Sélections
        </FormLabel>
        <RadioGroup
          required
          onClick={handleArrayChoice}
          id="id"
          defaultValue=""
          aria-label="Experience"
          name="customized-radios"
        >
          {renderCheckboxes()}
        </RadioGroup>
        <fieldset className={classes.fieldSetWrapper}>
        <legend className={classes.smallTitle}>
              Nb jours de disponibilité par semaine
                            </legend>
                                          <FormGroup>
              <Select
                        value={daysAvailability}
                        onChange={(event) => handleChange(event,"days")}
                        displayEmpty
                        className={classes.selectEmpty}
                      >
                        <MenuItem value={1}>1 jour</MenuItem>
                        <MenuItem value={2}>2 jours</MenuItem>
                        <MenuItem value={3}>3 jours</MenuItem>
                        <MenuItem value={4}>4 jours</MenuItem>
                        <MenuItem value={5}>5 jours</MenuItem>
                        <MenuItem value={0}>A discuter</MenuItem>
                </Select>
              </FormGroup>
        </fieldset>
        <fieldset className={classes.fieldSetWrapper}>
              <legend className={classes.smallTitle}>
              Mois de disponibilité
                            </legend>
              <FormGroup>
              <Select
                multiple
                value={monthsAvailability}
                onChange={(event) => handleChange(event,"months")}
                input={<Input />}
                renderValue={(selected) => selected.map(index => months[index]).join(', ')}
              >
                {months.map((name,index) => (
                  <MenuItem key={name} value={index}>
                    <Checkbox checked={monthsAvailability.indexOf(index) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
              </FormGroup>
        </fieldset>
      </div>
    );
  }


  const removeNull = (array) => {
    return array.filter(x => x !== null && x !== undefined)
  };

  useEffect(() => (updatedJob = jobs), [jobs]);

  const handleJobs = (data) => {
    setSelectedData({});
    if (!Array.isArray(data)) {
      data = [data];
    }
    const value = [];
    let max;
    if (userType === 'Candidate') {
      max = 3;
    } else {
      max = 5;
    }
    data = removeNull(data)

    if (data === null) return [];

    if (data.length <= max) {
      for (let i = 0, l = data.length; i < l; i += 1) {
        let objToPush = {
          id: null,
          selectedData
        };

        // if we have updatedJob object, get the data to push as an object
        if (updatedJob) {
          for (let z = 0; z < updatedJob.length; z++) {
            if (data[i].id !== updatedJob[z].id) {
              continue;
            }
            objToPush = jobs[z];
          }
        }
        // else we get the new data entry
        if (!objToPush.id) {
          objToPush.id = data[i].id;
        }
          value.push(objToPush);

          // load data needed for this job
        getJobRequirements(objToPush.id)
          .then((response) => {
            let generalEducationList = [];
            let experienceLevelsList = [];
            let specificEducationList = [];
            let workTypeList = [];
            let materialTypeList = [];
            let otherSpecificList = [];
            if (response.total > 0) {
              experienceLevelsList = response.results.ExperienceLevels;
              generalEducationList = response.results.GeneralEducation;
              specificEducationList = response.results.SpecificEducation;
              workTypeList = response.results.WorkType;
              materialTypeList = response.results.MaterialType;
              otherSpecificList = response.results.OtherSpecific;
            }

            setInitialdata({
              experienceLevels: experienceLevelsList,
              generalEducation: generalEducationList,
              specificEducation: specificEducationList,
              workType: workTypeList,
              materialType: materialTypeList,
              otherSpecific: otherSpecificList,
            });
          });
      }
    }

    setJobs(value);
    setShowNextSteps(true);
    onFormChange({
      target: {
        name: 'jobs',
        value
      }
    });
  };

  const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Object]';
  };

  const onTextChanged = (event) => {
    const metier_to_remove = [];
    allUserMetier.map((element) => {
      metier_to_remove.push(element.metier_id);
    });

    if (!loading) {
      setLoading(true);
      const query = event.target.value;
      if (query === null) {
        setJobList([]);
        setIsOpen(false);
      } else {

        if(formData.byWorkType && formData.workType!=null){
          getMetiersSearchByWorkType(formData.workType.id,query,"loan").then((response) => {
            if(isObject(response) ? Array.isArray(response.results) : false){
              const result2 = response.results.filter((el) => !metier_to_remove.includes(el.id));
              if (result2.length > 0) {
                setJobList(result2);
                setIsOpen(true);
              }
            }
            setLoading(false);
          });
        }else{
          getMetiersSearch(query,"loan").then((response) => {
            if(isObject(response) ? Array.isArray(response.results) : false){
              const result2 = response.results.filter((el) => !metier_to_remove.includes(el.id));
              if (result2.length > 0) {
                setJobList(result2);
                setIsOpen(true);
              }
            }
            setLoading(false);
          });

        }

        
      }
    }
  };

  const errors = {};
  const validateForm = (verifyAll = false) => {
    if (jobs || verifyAll) {
      if (jobs.length === 0 || jobs.length === null) {
        errors.jobs = 'Vous devez choisir au moins un emploi ainsi que le nombre d\'années d\'expérience relatif à l\'emploi sélectionné';
      }
    }
    if (!selectedData.experienceLevels) {
      errors.jobs = 'Vous devez sélectionner le nombre d\'années d\'expérience de travail relatif à l\'emploi sélectionné';
      jobRef.current.scrollIntoView()    
    }else{
      let cntCompletion = (selectedData?.workType ? selectedData?.workType?.length : 0 ) + (selectedData?.materialType ? selectedData?.materialType?.length : 0 ) + (selectedData?.otherSpecific ? selectedData?.otherSpecific?.length : 0 ) 
      if (cntCompletion < 3) {
        errors.jobs = "Vous devez renseigné les informations relatif au type de travail, type de matériel ou outils spécifique (min 3)";
        jobRef.current.scrollIntoView()   
      }
  
    }



    setFormErrors({
      ...errors,
      verifyAll
    });
    return Object.keys(errors).length < 1;
  };

  const { updateUser } = useSessionContext();
  selectedData.remote = remote;
  selectedData.days_availability = daysAvailability;
  selectedData.months_availability = monthsAvailability;
  selectedData.table_type = 'loan';

  const handleSubmit = () => {
    const raw = {
      user: {
        selected_data: selectedData,
        metiers: jobs,
        number_of_stars: numberOfStars
      },
      
    };

    if (validateForm(true)) {
      updateUser(raw)
        .then((response) => {
          if (response.status === 400) {
            if (response.data.error) {
              setFormErrors({
                ...errors,
                verifyAll: true
              });
            }
          }
          if (response.status === 200) {
            handleClose();
            metierCreated();
          }
        });
    }
  };

  const handleSwitchChange = (event) => {
    onFormChange({
      target: {
        name: 'byWorkType',
        value: event.target.checked,
      },
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h2" className={classes.smallTitle}>
          {userType === 'Enterprise' ? (
            <>
              Sélectionnez le/les métiers que vous recherchez (max 5)
            </>
          ) : (
            <>
              Sélectionnez votre profession
            </>
          )}
        </Typography>
        <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                className={classes.switch}
                control={<Switch checked={formData.byWorkType} disabled={jobs!=null && jobs.length > 0} onChange={handleSwitchChange} />}
                label="Filtrer la liste des métiers selon une compétence"
              />
            </FormGroup>
        </FormControl>
        {formData.byWorkType && (
          <>
            <WorkTypeAutoComplete
              name="workType"
              onFormChange={onFormChange}
              errors={errors}
              formData={formData}
            />
            <br/>
          </>
        )}
        <Autocomplete
          multiple={jobLimit != 1}
          onOpen={onTextChanged}
          onClose={() => setJobList([])}
          limitTags={jobLimit || 3}
          name="jobs"
          id="jobs"
          loading={loading}
          options={jobList}
          getOptionLabel={(option) => option.title}
          key={(option) => option.id}
          disabled={formData.byWorkType && formData.workType==null && jobList!=null && jobList.length==0}
          onChange={(event, value) => handleJobs(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={onTextChanged}
              variant="outlined"
              error={!!errors.jobs}
              label={formData.byWorkType ? "Liste des métiers correspondants" : "Appelation d'emploi (en majuscules sans accents)"}
              placeholder="Emploi"
            />
          )}
          ref={jobRef}
        />
        {formErrors.jobs && (
          <p className={`${classes.error} ${'error'}`}>{formErrors.jobs}</p>
        )}
        <>{showNextSteps && createCheckBoxes()}</>
      </Grid>
      <DialogActions>
        <Button onClick={handleSubmit}>Sauvegarder</Button>
      </DialogActions>
    </>
  );
};

export default PretSelectionSteps;
