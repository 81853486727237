import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import theme from '../../theme';
import useSessionContext from '../../sessions/useSessionContext';
import Metiers from './Metiers';
import Services from './Partner/Services';
import Destinations from './Destinations';
import CVProfile from './CVProfile';
import Alert from '@material-ui/lab/Alert';
import Langues from './shared/Langues';
import Contracts from './Contracts';
import EnterpriseInfo from './EnterpriseInfo';
import ListOfValues from './ListOfValues';


const useStyles = makeStyles((theme) => ({
    root:{
        width: '100%'
    }
}));

const Profile = () => {
    const classes = useStyles(theme);
    const { user } = useSessionContext();
    const [profileInfo, setProfileInfo] = useState({
        typeOfUser: '',
        profilePic: '',
        email: '',
        firstName: '',
        lastName: '',
        fullName: '',
        enterpriseName: '',
        jobs: [],
        languages: [],
        baseCountry: [],
        destinations: [],
        website: '',
        baseLocation: [],
        partner_locations: [],
        partner_services: [],
        isJobImported: false,
        numberCandidatesOrEnterprises: 0,
        metierWithMostCandidatesOrEnterprises: '',
        isSubscribed: 0,
        subscribedTo: {},
        linkedCv: [],
        cvFilename: null,
    });
    const [lack, setLack] = useState({ metier: false, location: false, service: false });
    const steps = [
        { selector: ".add-metier-btn", title: "Première étape", description: "Ajouter un métier" },
        { selector: ".add-location-btn", title: "Deuxième étape", description: "Ajouter une localisation" },
        { selector: ".match-tab", title: "C'est tout!", description: "Consulter vos matchs!" },
    ];

    useEffect(() => {
        const otherUserData = () => {
            let otherData = {};
            switch (user.type_of_user) {
                case 'Candidate':
                    otherData = {
                        baseLocation: user.candidate_location,
                        jobs: user.job_experience,
                        destinations: user.candidate_destinations,
                        metiers: user.metiers,
                        matches: user.matches,
                        linkedCv: user.linked_cv ?? [],
                        cvFilename: user.cv_filename,
                    };
                    break;
                case 'Enterprise':
                    otherData = {
                        enterpriseName: user.enterprise_name,
                        website: user.website,
                        jobs: user.job_locations,
                        destinations: user.job_locations,
                        metiers: user.metiers,
                        matches: user.matches,
                        isSubscribed: user.is_subscribed,
                        subscribedTo: user.subscribed_to,
                        employeeCount: user.employee_count,
                    };
                    break;
                case 'Partner':
                    otherData = {
                        enterpriseName: user.enterprise_name,
                        website: user.website,
                        partner_locations: user.partner_locations,
                        partner_services: user.user_services,
                        employeeCount: user.employee_count,
                        global: user.global
                    };
            }
            return otherData;
        };
        const userInfo = {
            id: user.id,
            typeOfUser: user.type_of_user,
            profilePic: user.profile_pic,
            fullName: user.full_name,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            languages: user.languages,
            isJobImported: user.is_job_imported,
            numberCandidatesOrEnterprises: user.number_candidates_or_enterprises,
            metierWithMostCandidatesOrEnterprises:
                user.metier_with_most_candidates_or_enterprises,
            ...otherUserData(),
        };
        setProfileInfo(userInfo);
        updateMissing();

    }, [user]);

    const updateMissing = () => {
        const lc = { metier: false, location: false, service: false , cv: false};
        if (user.type_of_user === 'Partner') {
            if (user.user_services.length === 0) {
                lc.service = true;
            }
            if (user.partner_locations.length === 0 && !user.global) {
                lc.location = true;
            }
        } else if (user.type_of_user === 'Enterprise') {
            if (user.metiers.length === 0) {
                lc.metier = true;
            }
            if (user.job_locations.length === 0) {
                lc.location = true;
            }

        }
        else if (user.type_of_user === 'Candidate') {
            if (user.metiers.length === 0) {
                lc.metier = true;
            }
            if (user.candidate_destinations.length === 0) {
                lc.location = true;
            }
            if (user.cv_filename === null || user.cv_filename === '') {
                lc.cv = true;
            }

        }
        setLack(lc)
    };

    const textToDisplay = () => {
        if (profileInfo.typeOfUser === 'Partner') {
            if (lack.service && lack.location) {
                return ("Pour commencer à recevoir des matchs veuillez inscrire au moins un service et une localisation");
            }
            if (lack.service) {
                return ("Pour commencer à recevoir des matchs au moins un service");
            }
            if (lack.location) {
                return ("Pour commencer à recevoir des matchs veuillez inscrire au moins une localisation");
            }
        } else if (profileInfo.typeOfUser === 'Candidate') {
            if (lack.metier && lack.location && lack.cv) {
                return ("Pour activer la recherche d'emploi veuillez inscrire au moins un métier et une destination et télécharger votre CV");
            }
            if (lack.metier && lack.location) {
                return ("Pour activer la recherche d'emploi veuillez inscrire au moins un métier et une destination");
            }
            if (lack.metier && lack.cv) {
                return ("Pour activer la recherche d'emploi veuillez inscrire au moins un métier et télécharger votre CV");
            }
            if (lack.location && lack.cv) {
                return ("Pour activer la recherche d'emploi veuillez inscrire une destination et télécharger votre CV");
            }
            if (lack.metier) {
                return ("Pour activer la recherche d'emploi veuillez inscrire au moins un métier");
            }
            if (lack.location) {
                return ("Pour activer la recherche d'emploi veuillez inscrire au moins une destination");
            }
            if (lack.cv) {
                return ("Pour activer la recherche d'emploi veuillez télécharger votre CV");
            }
        }else if (profileInfo.typeOfUser === 'Enterprise') {
            if (lack.metier && lack.location) {
                return ("Pour commencer à recevoir des matchs veuillez inscrire au moins un métier et une localisation");
            }
            if (lack.metier) {
                return ("Pour commencer à recevoir des matchs veuillez inscrire au moins un métier");
            }
            if (lack.location) {
                return ("Pour commencer à recevoir des matchs veuillez inscrire au moins une localisation");
            }
        }

    };

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            className={classes.root}
            component="form"
        >

            <List component="nav" className={classes.root}>
                {(lack.metier || lack.location || lack.service || lack.cv) && (
                    <ListItem divider>
                        <Alert severity="warning">{textToDisplay()}</Alert>
                    </ListItem>
                )}
                 {profileInfo.typeOfUser != 'Candidate' && (
                    <ListItem divider>
                        <EnterpriseInfo profileInfo={profileInfo} setProfileInfo={setProfileInfo} />
                    </ListItem>
                 )}
                 {profileInfo.typeOfUser === 'Candidate' && (
                    <ListItem divider>
                        <CVProfile profileInfo={profileInfo} setProfileInfo={setProfileInfo} />
                    </ListItem>
                )}
                <ListItem divider>
                    {profileInfo.typeOfUser === 'Candidate' ? (
                        <Metiers
                            isImported={profileInfo.isJobImported}
                            jobs={profileInfo.jobs}
                            metiers={profileInfo.metiers}
                            isSubscribed
                            subscribedTo=""
                        />
                    ) : profileInfo.typeOfUser === 'Enterprise' ? (
                        <Metiers
                            metiers={profileInfo.metiers}
                            isImported={profileInfo.isJobImported}
                            jobs={profileInfo.jobs}
                            typeOfUser="Enterprise"
                            isSubscribed={profileInfo.isSubscribed}
                            subscribedTo={profileInfo.subscribedTo.name}
                        />
                    ) : profileInfo.typeOfUser === 'Partner' ? (
                        <Services services={profileInfo.services} />
                    ) : null}
                </ListItem>
                <ListItem divider>
                    {profileInfo.typeOfUser === 'Candidate' ? (
                        <Destinations
                            typeOfUser={profileInfo.typeOfUser}
                            locationInfo={profileInfo.destinations}
                        />
                    ) : profileInfo.typeOfUser === 'Enterprise' ? (
                        <Destinations
                            typeOfUser={profileInfo.typeOfUser}
                            locationInfo={profileInfo.destinations}
                        />
                    ) : profileInfo.typeOfUser === 'Partner' ? (
                        <Destinations
                            typeOfUser={profileInfo.typeOfUser}
                            locationInfo={profileInfo.services_locations}
                        />
                    ) : null}
                </ListItem>
                


                {profileInfo.typeOfUser != 'Partner' && (
                    <>
                        <ListItem divider>
                            <Langues
                                typeOfUser={profileInfo.typeOfUser}
                                languages={profileInfo.languages}
                            />
                        </ListItem>
                        <ListItem divider>
                            <Contracts typeOfUser={profileInfo.typeOfUser} />
                        </ListItem>
                        <ListItem divider>
                            <ListOfValues />
                        </ListItem>
                    </>

                )}
            </List>

        </Grid>
    );
};

export default Profile;
