import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardHeader,
  Fab,
  Tooltip,
  Chip
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import theme from '../../theme';
import JobModal from './FormModal/JobModal';
import useSessionContext from '../../sessions/useSessionContext';
import FormConfirmMetierDelete from '../MetierConfirmationBox';
import MetierActivator from '../MetierActivator';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  rootCard: {
    width: '100%',
  },
  profileTabTitle: {
    '& span:first-child': {
      fontWeight: 600,
      fontSize: '1.25rem',
      color: '#E5087E',
    },
    '& span:last-child': {
      fontWeight: 500,
      fontSize: '1.05rem',
    },
  },
  profileMessageTitle: {
    '& span': {
      fontWeight: 600,
      fontSize: '1.25rem',
      color: '#080000',
    },
  },
  jobListHeader: {
    borderBottom: '1px solid #ebebeb',
  },
  jobListContainer: {
    borderBottom: '1px solid #ebebeb',
    position: 'relative',
  },
  jobCardActionsWrapper: {
    display: 'flex',
    padding: '10px 0px',
    justifyContent: 'flex-end'
  },
  jobCardActionsViewWrapper: {
    marginRight: 16,
  },
  ButtonModalStyle: {
    color: '#ffffff',
    backgroundColor: '#E5087E',
    width: 38,
    height: 38,
    padding: 10,
    borderRadius: 50,
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#E5087E',
    },
    '&:focus, &.focus': {
      backgroundColor: '#ffffff',
      color: '#E5087E',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
  disabledItem: {
    opacity: 0.3,
  },
  incompleteText: {
    paddingLeft: '15px',
    paddingTop: '-10px',
    color: 'red',
    fontSize: '12pt',
    [theme.breakpoints.down('xl')]: {
      paddingLeft: '15px',
      paddingTop: '10px',
      color: 'red',
      fontSize: '12pt',
    },
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      width: '100%',
      paddingLeft: '15px',
      paddingTop: '0',
      color: 'red',
      fontSize: '11pt',
    },
  },
  metierTitle:{
    '& span': {
      fontSize: '1.1rem',
    },
  },
  metierInactiveTitle:{
    '& span': {
      fontSize: '1.1rem',
    },
  },
  warningChip:{
    backgroundColor: '#ff9800',
    '& .MuiChip-icon' :{
      color: 'white'
    }
  }
}));

const Metiers = (props) => {
  const { user, refreshUserData } = useSessionContext();
  const [userMetiers, setUserMetiers] = useState([]);
  const [metierData, setMetierData] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const openEditModal = () => setShowEditModal(true);
  const closeEditModal = () => setShowEditModal(false);
  const [showViewMetierModal, setShowViewMetierModal] = useState(false);
  const openViewMetierModal = () => setShowViewMetierModal(true);
  const closeViewEditModal = () => setShowViewMetierModal(false);
  const { isSubscribed, subscribedTo } = props;
  const [disableItem, setDisableItem] = useState(true);
  const [activeArray, setActiveArray] = useState([]);
  const [inactiveArray, setInactiveArray] = useState([]);


  useEffect(() => {
    setUserMetiers(user.metiers);
  }, []);

  useEffect(() => {
    metierActiveList(user.metiers);
  }, []);

  const [show, setShow] = useState(false);

  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);

  const classes = useStyles(theme);

  const handleOpenViewMetierModal = (metierId, userMetierId, metierName) => {
    setMetierData({
      metierId,
      userMetierId,
      metierName,
    });
    openViewMetierModal();
  };

  const handleOpenEditModal = (metierId, userMetierId, metierName) => {
    setMetierData({
      metierId,
      userMetierId,
      metierName,
    });
    openEditModal();
  };

  const disableMetierModal = () => {
    const userType = user.type_of_user;
    const metierList = user.metiers;
    let numberOfUserMetier = null;

    if (userType === 'Candidate') {
      numberOfUserMetier = 3;
    } else if (!isSubscribed) {
      numberOfUserMetier = 5;
    } else if (
      subscribedTo == 'Relève' ||
      subscribedTo == 'Veille & Stratégie'
    ) {
      numberOfUserMetier = 10;
    } else {
      numberOfUserMetier = 5;
    }

    if (numberOfUserMetier <= metierList.length) {
      return (
        <>
          <Grid item xs={10}>
            <CardHeader
              title="Mes métiers"
              subheader={user.type_of_user=='Candidate' ? "Renseignez votre (vos) profession(s) (3 max)" : "Renseignez le/les métiers que vous recherchez"}
              className={classes.profileTabTitle}
            />
          </Grid>
          <Grid
          item
          xs={2}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
            <Tooltip title="Vous avez atteint le maximum de métier permis !">
              <span>
                <Fab color="primary" disabled aria-label="add">
                  <AddIcon />
                </Fab>
              </span>
                
            </Tooltip>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid item xs={10}>
          <CardHeader
            title="Mes métiers"
            subheader={user.type_of_user=='Candidate' ? "Sélectionnez votre (vos) profession(s) (3 max)" : "Sélectionnez le/les métiers que vous recherchez"}
            className={classes.profileTabTitle}
          />
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Fab
            className='add-metier-btn'
            id="addJob"
            color="secondary"
            aria-label="add"
            onClick={() =>{
              refreshUserData();
              openModal();
            }}
          >
            <AddIcon />
          </Fab>
        </Grid>
      </>
    );
  };

  const metierActiveList = (metierData) => {
    const activeArr2 = [];
    const inactiveArr2 = [];
    metierData.forEach((metier) => {
      if (metier.is_active === true) {
        activeArr2.push(metier);
      }

      if (metier.is_active === false) {
        inactiveArr2.push(metier);
      }
    });
    setActiveArray(activeArr2);
    setInactiveArray(inactiveArr2);
  };

  const renderButtons = (metier) => {
    if (user.type_of_user === 'Enterprise' && isSubscribed === true) {
      return (
        <>
          <div className={classes.jobCardActionsViewWrapper}>
            <MetierActivator
              metier={metier}
              subscribedTo={subscribedTo}
              activeArray={activeArray}
              inactiveArray={inactiveArray}
            />
          </div>
          <div className={classes.jobCardActionsViewWrapper}>
            <FormConfirmMetierDelete metier={metier} />
          </div>
        </>
      );
    }
    if (user.type_of_user === 'Enterprise' && isSubscribed === false) {
      return (
        <div className={classes.jobCardActionsViewWrapper}>
          <FormConfirmMetierDelete metier={metier} />
        </div>
      );
    }
    return (
      <div className={classes.jobCardActionsViewWrapper}>
        <FormConfirmMetierDelete metier={metier} />
      </div>
    );
  };

  const disabledEditCondition = (metier) => {
    if (metier.is_active) {
      return (
        <>
          <Fab
            id={`editJob-${metier.user_metier_id}`}
            color="secondary"
            aria-label="edit"
            onClick={() =>{
              refreshUserData();
              handleOpenEditModal(
                metier.metier_id,
                metier.user_metier_id,
                metier.metier_name,
              )
            }}
          >
            <EditIcon />
          </Fab>
        </>
      );
    }
    return (
      <>
        <Fab
          id={`editJob-${metier.user_metier_id}`}
          color="secondary"
          aria-label="edit"
          onClick={() => {
            refreshUserData();
            handleOpenEditModal(
              metier.metier_id,
              metier.user_metier_id,
              metier.metier_name,
            )
          }}
          disabled={disableItem}
        >
          <EditIcon />
        </Fab>
      </>
    );
  };

  const CarteMetiers = (metiersArray) => {
    const carteArr = [];
    metiersArray.forEach((metier, inx) => {
        carteArr.push(
          <Grid
            key={inx}
            container
            alignItems="center"
            className={`${classes.jobListContainer} job-card-container`}
          >
            {metier.is_active === true ? (
              <>
                <Grid item sm={6} xs={9}>
                  <CardHeader title={metier.metier_name} className={classes.metierTitle}/>
                </Grid>
                <Grid item sm={2} xs={3}>
                  {(!metier.completed) && <Chip size="small" label='Incomplet' icon={<WarningIcon />} className={classes.warningChip} />}
                </Grid>
              </>
            ) : (
              <>
                <Grid item sm={6} xs={9}>
                  <CardHeader
                    title={metier.metier_name}
                    className={classes.metierInactiveTitle}
                    style={{ opacity: '0.5' }}
                  />
                </Grid>
                <Grid item sm={2} xs={3}>
                    <Chip size="small" label='Inactif' icon={<InfoIcon />} />
                </Grid>
              </>
            )}
  
            <Grid item sm={4} xs={12} className={classes.jobCardActionsWrapper} >
              <div className={classes.jobCardActionsViewWrapper}>
                <Fab
                  color="secondary"
                  aria-label="view"
                  onClick={() => {
                    refreshUserData();
                    handleOpenViewMetierModal(
                      metier.metier_id,
                      metier.user_metier_id,
                      metier.metier_name,
                    )
                  }}
                  className={classes.jobCardActionsView}
                >
                  <VisibilityIcon />
                </Fab>
              </div>
              <div className={classes.jobCardActionsViewWrapper}>
                {disabledEditCondition(metier)}
              </div>
              {renderButtons(metier)}
            </Grid>
          </Grid>,
        );
    });
    return carteArr;
  };
  
  return (
    <Grid container>
      <Card square className={classes.rootCard} elevation={0} variant="outlined">
        <Grid container alignItems="center">
          {disableMetierModal()}
        </Grid>
        
        {CarteMetiers(user.metiers)}
      </Card>
      <JobModal
          closeModal={closeEditModal}
          metierData={metierData}
          show={showEditModal}
          editMetier
        />
        <JobModal
          closeModal={closeViewEditModal}
          metierData={metierData}
          show={showViewMetierModal}
          viewMetier
        />
      <JobModal
        closeModal={closeModal}
        show={show}
        metierCreated={refreshUserData}
        createMetier
        userMetiers={user.metiers}
      />
    </Grid>
  );
};

export default Metiers;
